<template>
  <div class="outline rounded pa-2">
    <p>IMPORTANT NOTE!</p>
    <p class="text-h6">Your current browser is not combatible with this application.</p>
    <p class="caption">
      Please Install
      <a href="https://www.google.com/chrome">Chrome</a>
      or
      <a href="https://www.microsoft.com/en-us/edge/download">Edge</a>
      to log in.
    </p>
  </div>
</template>

<script>
export default {
  name: 'browser_compat_box',
};
</script>

<style scoped></style>
