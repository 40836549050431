import progressMessageMixin from '@/components/trade-dsm-application/mixins/progress-message';
import {mapGetters, mapMutations} from 'vuex';
import axios from 'axios';
import swal from 'sweetalert';
import {is_compatible_browser} from '@/components/application-level/js_functions/browser_checks.ks';
import {handle_error} from '@/components/application-level/helper-components/error_handler';
import {eventBus} from '@/main';
// import Swal from 'sweetalert2';

export default {
  mixins: [progressMessageMixin],

  mounted: function() {
    let favImg =
      'https://tradeadvisory.co.za/wp-content/uploads/2023/01/cropped-TradeAdvisoryIcon-180x180.png';
    let info_page = 'trade-advisory';
    if (localStorage.getItem('reload_url') === 'login_aexi') {
      document.title = 'AEXI Advanced Export Intelligence';
      favImg = 'https://aexi.nl/wp-content/uploads/2021/11/aexi-favicon.svg';
      info_page = 'aexi';
    } else {
      favImg =
        'https://tradeadvisory.co.za/wp-content/uploads/2023/01/cropped-TradeAdvisoryIcon-180x180.png';
    }

    let headTitle = document.querySelector('head');
    let setFavicon = document.createElement('link');
    setFavicon.setAttribute('rel', 'shortcut icon');
    setFavicon.setAttribute('href', favImg);
    headTitle.appendChild(setFavicon);

    fetch(this.$store.getters.baseUrl + `article_of_the_day_general/${info_page}/`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    })
      .then(response => {
        if (response.ok) {
          response.json().then(data => (this.article = data));
        }
      })
      .catch(error_code => {
        console.log(error_code);
        let error_msg = error_code.message;
        this.article = this.backend_error;
        // this.article.p1 = error_msg;
        // this.article.p2 = error_code;
        // this.login_error = false;
        // this.loggin_in = false;
        // this.backend_alive = false;

        handle_error({
          code: 'backend_dead',
          message: error_msg,
        });
      });
  },

  data() {
    return {
      clear_casch_selected: false,
      username: '',
      password: '',
      token: '',
      refresh: '',
      article: {},
      login_error: false,
      loggin_in: false,
      backend_alive: true,
      skip_authentication: false,
      expired: false,
      appThatExpired: '',
      custom_filers_definition: null,
      backend_error: {
        Title: 'We could not communicate with the Application Server',
        Headline: 'This could mean that:',
        p1:
          '1) Most probably your institutional firewall is blocking access - please contact your internal IT support.',
        p2:
          ' 2) Our server could be down, but we would know about it and alert you if this was the case.',
        p3: '',
      },
    };
  },
  computed: {
    ...mapGetters(['countriesSelectorList', 'user', 'user_type', 'user_apps']),
  },

  methods: {
    // notes on JWt https://alligator.io/vuejs/vue-jwt-patterns/
    ...mapMutations(['set_user_message']),

    is_compatible_browser: is_compatible_browser,
    async set_JWT_refresh() {
      window.setInterval(async () => {
        // console.log('Checking current refresh token.');
        await axios({
          method: 'post',
          url: this.$store.getters.baseUrl + 'api/refresh/',
          data: {
            refresh: localStorage.getItem('refresh'),
          },
        })
          .then(response => {
            // console.log('refresh worked', response.data);
            localStorage.setItem('token', response.data['access']);
          })
          .catch(error => {
            this.$router.push('login').catch(() => {}); //the catch block should remove the navitation duplicated error
            console.log(error);
          });
      }, 1000 * 60 * 60 * 2); // 1000 ms -> 1s -> 60seconds -> 60 minutes - -> twohours
    },
    async change_application(application) {
      if (JSON.parse(localStorage.getItem('startup_with_filters')) === false) {
        await this.reset_filters_to_raw_state();
      }
      console.log(application);
      this.$store.state.app.selected_application = application;
      // await this.$router.push(application).catch(() => {});
      await this.$router.push('trade-dsm');
    },

    async clear_cache() {
      let reload_url = localStorage.getItem('reload_url');
      await localStorage.removeItem('currency');
      await localStorage.removeItem('magnitude');
      // await localStorage.removeItem('dsm_codes');
      await localStorage.removeItem('dsm_countries');
      await localStorage.removeItem('FILTERS');
      await this.$removeItem('MASTER_STATE');
      await this.$removeItem('countries-filter-open-backup');
      await this.$removeItem('1pmc-backup');
      await this.$removeItem('filters-open-backup');
      await localStorage.removeItem('FILTERS_PRESET');
      // await localStorage.removeItem('last_market_model');
      await localStorage.setItem('reload_url', reload_url);
    },
    async application_local_update(current_version) {
      // Check if the application local data should be cleared.
      console.log(`Last application version: ${current_version}`);
      let update_app = false;

      // let outer_version = null;

      // is there a key
      if (localStorage.getItem('last_update') === null) {
        console.log('There is no update key yet');
        localStorage.setItem('last_update', current_version);
        update_app = true;
      } else {
        const local_version = parseFloat(localStorage.getItem('last_update'));
        // outer_version = local_version;
        console.log(
          `Latest version: ${current_version}, Local version: ${local_version}`,
        );

        if (local_version < current_version) {
          console.log('We need to update the local storage');
          update_app = true;
        } else {
          console.log('No need to update local storage');
          update_app = false;
        }
      }

      if (update_app) {
        await this.clear_cache();
        await swal(
          'A newer application version is available.\n\nPlease be patient while your version is upgraded to V' +
            current_version,
          {
            buttons: false,
            closeOnClickOutside: false,
            closeOnEsc: false,
            timer: 1500,
          },
        );

        localStorage.setItem('last_update', current_version);
        // localStorage.clear();
      }
    },

    async wait_for_application_number_selection() {
      return new Promise(resolve => {
        // Setup a watcher
        const unwatch = this.$watch(
          () => this.$store.state.app.application_selection_number,
          newValue => {
            // Check the condition for the value
            if (newValue !== null) {
              // If the condition is met, resolve the Promise
              resolve(newValue);
              // And unwatch the watcher to avoid memory leaks
              unwatch();
            }
          },
        );
      });
    },
    async user_info() {
      let resp_config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      };
      let model = undefined;
      await this.axios
        .get(this.$store.getters.baseUrl + 'user_info/', resp_config)
        .then(async response => {
          let response_data = response.data[0];

          // TODO this is where the subscriptions is coming in

          await this.$store.commit('setUserInfo', response_data);

          if (response_data['subscriptions'].filter(v => v.active).length > 1) {
            swal.close();
            this.$store.state.app.application_selector_open = true;
            model = await this.wait_for_application_number_selection();
          } else {
            model = 0;
          }

          if (response_data['subscriptions'][model]['active'] === false) {
            this.$store.state.tradeDSM.loading_indicator = false;
            await swal({
              title: 'You do not have an active Trade-DSM subscription.',
              text: 'Please contact your system administrator',
              icon: 'error',
              dangerMode: true,
            });
            this.username = '';
            this.password = '';
            this.loggin_in = false;
            // await this.$router.push('login');
            location.reload();
          } else {
            const include_trade_history_test_one =
              response_data['app_config']['include_trade_history_test_one'];

            const use_shared_tables =
              response_data['app_config']['test_shared_tables_use'];
            const shared_tables_number =
              response_data['app_config']['test_shared_tables_model_number'];
            let is_dev_mode = false;
            if (process.env.VUE_APP_DEVELOPMENT === 'development') {
              is_dev_mode = true;
            }

            let msg = 'DEBUG Information\n\n';
            if (include_trade_history_test_one && is_dev_mode) {
              msg += 'Full_Trade_Data_Test_1 : Will be used. \n\n';
            }

            if (use_shared_tables && is_dev_mode) {
              msg += 'Test_' + shared_tables_number + ' tables :  Will be used.';
            }

            if (
              (include_trade_history_test_one && is_dev_mode) ||
              (use_shared_tables && is_dev_mode)
            )
              await swal(msg, {
                timer: 3000,
              });

            this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.instituion_id =
              response_data['user']['institution_id'];
            // this.$store.commit('setUserInfo', response_data);
            this.loggin_in = false;
          }

          console.log('after_the_while');
        });
      return model;
    },

    async app_selected(
      newApp,
      home_maket_name,
      active,
      model_version,
      sub_national_name,
      home_market_code,
    ) {
      // console.log('home_market_code');
      if (active) {
        if (newApp.toLowerCase().includes('dsm')) {
          // load codes if needed

          console.log(
            'Model used: ' +
              newApp +
              '-' +
              home_maket_name +
              '(' +
              sub_national_name +
              ')',
          );
          this.$store.commit(
            'appSelected',
            newApp + '-' + home_maket_name + '(' + sub_national_name + ')',
          );
          document.title = 'DSM - ' + home_maket_name + ' (' + model_version + ')';
          this.$store.commit('setHomeCountry', home_maket_name);
          this.$store.commit('setHomeMarket', home_market_code);
          this.$store.commit('setModelVersion', model_version);
          this.$store.commit('setModelName', sub_national_name);

          // console.log('Trade-DSM application ready ...');
          this.setProgressMessage('Welcome to Trade-DSM');
        } else {
          document.title = newApp;
          this.$store.commit('appSelected', newApp);
          this.$router.push('trade-sars-national');
        }
      } else {
        this.appThatExpired =
          'Application (' + newApp + '-' + home_maket_name + ') has expired.';
        this.expired = true;
      }
    },
    async load_preset_from_custom_filter(home_market_code, model_name) {
      //this loads from the new setup
      //lets load the deginitiom
      let data = this.$store.state.tradeDSM.dsm_filter_setttings.Filters;

      // load and setup state -
      let query_data = {
        institution_id: this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
          .instituion_id,
      };
      await this.axios
        .post(this.$store.getters.baseUrl + 'load_custom_filters/', query_data)
        .then(response => {
          this.custom_filers_definition = response.data['custom_filters_def'];
        })
        .catch(e => {
          console.log(e);
        });

      // which one to load
      let ix = JSON.parse(localStorage.getItem('custom_filters_selected'))[
        'startup_value_list_ix'
      ];

      const query_params = this.custom_filers_definition[ix]['query'];
      this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META['custom_query'] =
        query_params['main_filters'];

      if (this.$store.state.app.selectedModelName !== 'National') {
        this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META[
          'PROVINCIAL_FILTER_VALUE'
        ] = query_params['provincial_share'];
      }

      this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections.reo_clicked.REO_col =
        query_params['REO_col'];
      this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections.reo_clicked.REO_row =
        query_params['REO_row'];
      this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.presets.load_presets =
        query_params['load_presets'];
      this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.filter_in_progress = true;
      this.applied_filter = this.selected_custom_filer;

      ///sync selectors -
      let filter_headings = ['F1', 'F2', 'F3', 'F4'];

      let custom_filters = this.custom_filers_definition[ix]['query']['main_filters'];
      let custom_filers_keys = Object.keys(custom_filters);
      let backup_keys = custom_filers_keys;

      await filter_headings.forEach(heading => {
        let Filter_Values = this.$store.state.tradeDSM.dsm_filter_setttings.Filters[
          heading
        ]['entries'];
        Filter_Values.forEach(Filter => {
          if (custom_filers_keys.includes(Filter['col_name'])) {
            let v = custom_filters[Filter['col_name']] * 1;
            // console.log(`${Filter['col_name']} should be set to ${v},${v}`)
            Filter['selected_value'] = [v, v];
            backup_keys.splice(backup_keys.indexOf(Filter['col_name']), 1);
          }
        });
      });

      ///
      await this.run_dsm_advanced_query();
      await this.update_filters_selection();
      // await this.update_results_cube()
      await localStorage.setItem(
        'FILTERS_PRESET',
        JSON.stringify(this.$store.state.tradeDSM.dsm_filter_setttings.Filters),
      );

      data['META']['include_product_exclusions'] = false;
      let save_state = {};
      save_state[home_market_code] = {};
      save_state[home_market_code][model_name] = this.$store.state;
      // Note to self - removed to check if top filter change issues not due to this.
      await this.$setItem('MASTER_STATE', save_state);
      this.$store.commit('setupdatingMarketProductCombinations', false);
      this.set_user_message('Ready');

      //finally
      swal.close();
      if (JSON.parse(localStorage.getItem('startup_with_filters')) === false) {
        await this.reset_filters_to_raw_state();
      }
      this.set_user_message('Ready');
      // await this.$router.push('trade-dsm');
    },

    async set_up_app(model_number) {
      console.log(model_number);
      const app_list = this.user_apps.filter(v => v.subscription != null);
      console.table(app_list);
      if (this.user_apps.map(v => v.active === true).some(v => v === true)) {
        await this.app_selected(
          app_list[model_number].subscription['name'],
          app_list[model_number].subscription['home_market_name'],
          app_list[model_number].active,
          app_list[model_number].subscription['sub_national_code'],
          app_list[model_number].subscription['sub_national_name'],
          app_list[model_number].subscription['home_market_code'],
        );
      } else {
        swal({
          title: 'You do not have an active Trade-DSM subscription.',
          text: 'Please contact your system administrator',
          icon: 'error',
          dangerMode: true,
        });
        await this.$router.push('login');
      }
    },

    async log_userin() {
      await this.axios
        .post(this.$store.getters.baseUrl + this.$store.state.tradeDSM.login_url, {
          username: this.username,
          password: this.password,
        })
        .then(async reponse => {
          // eslint-disable-next-line no-console
          if (reponse.data) {
            this.token = reponse.data.access;
            this.refresh = reponse.data.refresh;
            await localStorage.setItem('token', this.token);
            await localStorage.setItem('refresh', this.refresh);
            await localStorage.setItem('username', this.username);
            this.$store.commit('loginSuccess');
          } else {
            this.login_error = true;
            this.backend_alive = true;
          }
        })
        .catch(error_code => {
          let error_msg = error_code.message;
          console.log('Martin error:');
          console.log(error_code);
          console.log(error_code);
          console.log('===============');
          this.article = this.backend_error;
          this.article.p1 = error_msg;
          // 401 error
          if (error_msg === 'Request failed with status code 401') {
            this.login_error = true;
            this.loggin_in = false;
            this.backend_alive = true;
            handle_error({
              code: 'auth_error',
              message: error_msg,
            });
          } else {
            // other error
            this.login_error = true;
            this.loggin_in = false;
            this.backend_alive = false;
            handle_error({
              code: 'backend_dead',
              message: error_msg,
            });
          }
          swal.close();
        });
      return !this.login_error;
    },

    async start_login_process() {
      let debug = false;
      if (debug === true) {
        // if (await this.log_userin()) {
        //   await this.change_application(
        //     this.$store.state.app.available_applications[1].value,
        //   );
        // }
        await this.change_application(
          this.$store.state.app.available_applications[1].value,
        );
      } else {
        if (this.clear_casch_selected) {
          let reload_url = localStorage.getItem('reload_url');
          await localStorage.clear();
          localStorage.setItem('reload_url', reload_url);
          localStorage.setItem('default_subscription', '0');
          localStorage.setItem('default_subscription_reduced_array_index', '0');
          eventBus.$emit('RELOAD_APPLICATION_MODEL_SELECTIONS');
        } else if (this.username !== localStorage.getItem('username')) {
          swal('Please be patient...\n\nLogging in new user', {
            buttons: false,
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
          let reload_url = localStorage.getItem('reload_url');
          await localStorage.clear();
          localStorage.setItem('default_subscription', '0');
          localStorage.setItem('default_subscription_reduced_array_index', '0');
          eventBus.$emit('RELOAD_APPLICATION_MODEL_SELECTIONS');
          await localStorage.setItem('reload_url', reload_url);
        }

        this.loggin_in = true;
        this.login_error = false;

        swal('Please be patient...\n\nAuthenticating', {
          buttons: false,
          closeOnClickOutside: false,
          closeOnEsc: false,
        });

        const login_success = await this.log_userin();

        this.get_hscodes();

        if (login_success) {
          swal('Please be patient...\n\nLoading User Information', {
            buttons: false,
            closeOnClickOutside: false,
            closeOnEsc: false,
          });

          this.set_user_message('Authenticating user');

          let selected_model = await this.user_info();

          const val = localStorage.getItem('startup_with_filters');

          if (val) {
            this.$store.state.app.startup_with_filters = val;
          } else {
            this.$store.state.app.startup_with_filters = false;
          }

          await this.set_JWT_refresh();

          // const app_list = this.user_apps.filter(v => v.subscription != null);

          // is there a last app?
          let last_market_model = localStorage.getItem('last_market_model');

          let last_market_model_json = JSON.parse(last_market_model);

          console.log('comparisons');
          let same_model = false;

          // test to see if it is the same model
          try {
            // console.log(last_market_model_json['home_market_code']);
            same_model = //test to see C_Code and Model sub nationsla eg. national/limpopo
              last_market_model_json['home_market_code'] ===
                this.user_apps[selected_model]['subscription']['home_market_code'] &&
              last_market_model_json['sub_national_name'] ===
                this.user_apps[selected_model]['subscription']['sub_national_name'];
            console.log(same_model);
          } catch (v) {
            console.log(v);
          }

          // if it is the same model, just move forward
          if (last_market_model && same_model) {
            // yes, so load it
            last_market_model = JSON.parse(last_market_model);
            console.log('Reading old model');
          } else {
            // not se same model, move ahead and load stuff so save the first one.
            console.log(
              `First run, setting model ('last_market_model') ${selected_model}`,
            );
            // this.clear_cache();
            await localStorage.setItem(
              'last_market_model',
              JSON.stringify(this.user_apps[selected_model]['subscription']),
            );
          }

          last_market_model = JSON.parse(localStorage.getItem('last_market_model'));
          console.log('Last market model');
          console.log(last_market_model);

          const model_number = last_market_model['sub_national_code'];
          const home_market_code = last_market_model['home_market_code'];
          const model_name = last_market_model['sub_national_name'].replace(/\s/g, '');
          console.log(model_number);
          console.log(home_market_code);
          console.log(model_name);

          this.set_user_message('Checking saved states');
          await this.application_local_update(
            parseFloat(process.env['VUE_APP_BACKEND_VERSION']),
          );

          if (JSON.parse(localStorage.getItem('startup_with_filters')) === null) {
            localStorage.setItem('startup_with_filters', JSON.stringify(false));
          }

          let passed_states = null;

          let states = await this.$getItem('MASTER_STATE');

          let is_there_states = states !== null;

          if (is_there_states !== null) {
            passed_states = is_there_states;
          }

          if (localStorage.getItem('currency') === null) {
            // localStorage.setItem('currency', JSON.stringify(this.currency_setting));
            await localStorage.setItem(
              'currency',
              JSON.stringify(this.$store.state.user.userServerInfo.exchange_rate[0]),
            );
          }

          console.log(
            !passed_states
              ? 'Not loading states from storage ...'
              : 'Loading states from storage ...',
          );

          // if (passed_states === false) {
          if (passed_states === false || same_model === false) {
            swal('Please be patient...\n\nLoading Countries', {
              buttons: false,
              closeOnClickOutside: false,
              closeOnEsc: false,
            });
            this.set_user_message('Loading markets list');
            await this.get_countries();

            swal('Please be patient...\n\nLoading Products\n\nAlmost Done!', {
              buttons: false,
              closeOnClickOutside: false,
              closeOnEsc: false,
            });
            this.set_user_message('Loading product list');
            // this.get_hscodes();

            swal('Please be patient...\n\nLoading Application Data', {
              buttons: false,
              closeOnClickOutside: false,
              closeOnEsc: false,
            });
            this.set_user_message('Configuring application');
            await this.set_up_app(selected_model);

            swal('Please be patient...\n\nLoading Filters', {
              buttons: false,
              closeOnClickOutside: false,
              closeOnEsc: false,
            });

            // ----------------------
            // load the filters into the main object
            // here the presets should be false?

            //156, 673, 10214, 4,391.19

            // await this.$setItem('selected_standard_filter_id', 2);
            if (localStorage.getItem('custom_filters_selected') === null) {
              localStorage.setItem(
                'custom_filters_selected',
                JSON.stringify({
                  startup_value_list_ix: 0,
                  current_value_list_ix: 0,
                }),
              );
            }
            let selected_standard_filter_id = await this.$getItem(
              'selected_standard_filter_id',
            );
            selected_standard_filter_id = JSON.parse(
              localStorage.getItem('custom_filters_selected'),
            )['startup_value_list_ix'];

            this.set_user_message('Loading preset filters');
            await this.load_dsm_filters(
              this.$store.state.tradeDSM.dsm_filter_setttings.Home_Market,
              this.$store.state.app.selectedModelVersion,
              'all',
              'all',
              this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.instituion_id,
              selected_standard_filter_id,
            );

            // set up the first run
            // this wil load the filters without any presets
            this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.presets.load_presets = false;
            this.set_user_message('Loading initial cubes');
            await this.run_dsm_advanced_query();
            localStorage.setItem(
              'FILTERS',
              JSON.stringify(this.$store.state.tradeDSM.dsm_filter_setttings.Filters),
            );

            // start of loading presets
            // -------------------------------------------------------
            // await this.load_preset_filters(home_market_code, model_name)
            let custom_filters_selected = JSON.parse(
              localStorage.getItem('custom_filters_selected'),
            );
            await localStorage.setItem(
              'last_loaded_list_ix',
              custom_filters_selected['startup_value_list_ix'],
            ); //make a note of the last one loaded
            await this.load_preset_from_custom_filter(home_market_code, model_name);
            await this.change_application(
              // this [0] is not the subscriptions but loads the default app - this is Trade APP
              this.$store.state.app.available_applications[0].value,
            );
            // return 0
            //--------------------------------------------------------------------------------------------------
          } else {
            await this.$store.replaceState(states[home_market_code][model_name]);

            // custom filter has it changed? because then we need to reload it
            // -------------------------------
            let last_loaded_list_ix = JSON.parse(
              localStorage.getItem('last_loaded_list_ix'),
            );
            let current_ix = JSON.parse(localStorage.getItem('custom_filters_selected'))[
              'startup_value_list_ix'
            ];

            if (last_loaded_list_ix !== current_ix) {
              console.log(
                `A preset filter change is being loaded. from ${last_loaded_list_ix +
                  1} to ${current_ix + 1}`,
              );
              swal(`Loading the custom filter list: ${current_ix + 1}`, {
                buttons: false,
                closeOnClickOutside: false,
                closeOnEsc: false,
              });
              await this.load_preset_from_custom_filter(home_market_code, model_name);

              await localStorage.setItem(
                'custom_filters_selected',
                JSON.stringify({
                  startup_value_list_ix: current_ix,
                  current_value_list_ix: current_ix,
                }),
              );
              await localStorage.setItem('last_loaded_list_ix', current_ix);
              // swal.close()
            }
            // -----------------------

            this.$store.state.tradeDSM.loading_indicator = false;
            this.$store.state.tradeDSM.updatingMainFilters = false;
            this.set_user_message('Application loadedz');

            // await this.$router.push('trade-dsm');
            await this.change_application(
              this.$store.state.app.available_applications[0].value,
            );
            swal.close();
          }

          // --------------------
        } else return false;
      }
      return true;
    },
  },
};
