<template :key="selected_application">
  <v-dialog
    v-model="select_application_open"
    persistent
    width="800"
    retain-focus
    class="rounded"
  >
    <v-card class="grey lighten-3" dark outlined rounded="lg">
      <v-card-title class="trade-blue-text text-h5 ml-2">
        <v-flex>
          <span> Please select custom model to use: </span>
          <help-link help_id="58" class="mr-2" style="float: right"></help-link>
        </v-flex>
      </v-card-title>

      <v-card-text>
        <v-row no-gutters>
          <v-col>
            <v-radio-group v-model="selected_application" hide-details class="mt-0">
              <div class="overflow-y-auto pa-2 rounded" style="max-height: 400px">
                <template v-for="(subscription, ix) in available_applications()">
                  <div class="filterItem trade-blue-text outline" :key="ix">
                    <v-row no-gutters>
                      <v-col class="trade-blue-text">
                        <v-radio
                          :key="ix"
                          class="caption trade-blue-text"
                          color="#1f3c5f"
                        >
                          <template v-slot:label>
                            <span
                              class="trade-blue-text "
                              :class="{'font-weight-bold': subscription.default}"
                              >{{ subscription['name'] }}
                            </span>
                          </template>
                        </v-radio>
                      </v-col>
                    </v-row>
                  </div>
                </template>
              </div>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-card-text>

      <div class="text-right mr-8 mt-1 mb-1">
        <v-btn class="trade-blue mr-2 v-btn--outlined" @click="set_selection"
          >Apply
        </v-btn>
        <v-btn
          class="trade-blue mr-2 v-btn--outlined"
          :disabled="!available_applications().length > 0"
          @click="set_default_value"
          >Set as default
        </v-btn>

        <!--        <v-btn class="trade-blue v-btn&#45;&#45;outlined" @click="close_window">Close </v-btn>-->
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import helpLink from '@/components/application-level/help-system/help-link';
import {eventBus} from '@/main';

export default {
  name: 'application_selector',
  props: {
    select_application_open: {},
  },
  components: {
    helpLink,
  },
  mounted() {
    this.load_default_array_index_value();
    eventBus.$on('RELOAD_APPLICATION_MODEL_SELECTIONS', () => {
      this.load_default_array_index_value();
    });
  },
  data: () => ({
    loaded_default: null,
    selected_application: 0,
  }),

  watch: {},
  computed: {},
  methods: {
    available_applications() {
      let subscriptions = this.$store.state.user.userServerInfo.subscriptions;

      let return_subscriptions = [];
      let index_of_default_inthis_array = 0;
      for (let i = 0; i < subscriptions.length; i++) {
        let subscription = subscriptions[i];
        if (subscription.active === true) {
          index_of_default_inthis_array = index_of_default_inthis_array + 1;
          let add = '';
          if (i === this.loaded_default) {
            add = '- (Default)';
          } else add = '';

          let s = subscription['subscription'];
          return_subscriptions.push({
            name: `${s['home_market_name']} (${s['sub_national_name']}) ${add} `,
            array_id: i,
            default: i === this.loaded_default,
          });
        }
      }
      return return_subscriptions;
    },
    load_default_array_index_value() {
      let default_value = localStorage.getItem('default_subscription');
      if (default_value === null) {
        this.loaded_default = 0;
        this.selected_application = 0;
        localStorage.setItem('default_subscription', '0');
        localStorage.setItem('default_subscription_reduced_array_index', '0');
      } else {
        this.loaded_default = parseInt(localStorage.getItem('default_subscription'));
        this.selected_application = parseInt(
          localStorage.getItem('default_subscription_reduced_array_index'),
        );
      }
    },
    close_window() {
      this.$store.state.app.application_selector_open = false;
    },
    set_selection() {
      this.$store.state.app.application_selection_number = this.available_applications()[
        this.selected_application
      ]['array_id'];

      this.close_window();
    },
    set_default_value() {
      localStorage.setItem(
        'default_subscription',
        this.available_applications()[this.selected_application]['array_id'].toString(),
      );
      localStorage.setItem(
        'default_subscription_reduced_array_index',
        this.selected_application.toString(),
      );
      this.set_selection();
    },
  },
};
</script>
