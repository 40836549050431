export function is_compatible_browser() {
  // returns true if the browser is "Chromy"

  let userAgentString = navigator.userAgent;

  // Detect Chrome
  // // Detect Internet Explorer
  // let IExplorerAgent =
  //     userAgentString.indexOf("MSIE") > -1 ||
  //     userAgentString.indexOf("rv:") > -1;
  //
  // // Detect Firefox
  // let firefoxAgent =
  //     userAgentString.indexOf("Firefox") > -1;
  //
  // // Detect Safari
  // let safariAgent =
  //     userAgentString.indexOf("Safari") > -1;
  //
  // // Discard Safari since it also matches Chrome
  // if ((chromeAgent) && (safariAgent))
  //     safariAgent = false;
  //
  // // Detect Opera
  // let operaAgent =
  //     userAgentString.indexOf("OP") > -1;
  //
  // // Discard Chrome since it also matches Opera
  // if ((chromeAgent) && (operaAgent))
  //     chromeAgent = false;
  //
  // console.log((`chromeAgent : ${chromeAgent}`))
  // console.log((`firefoxAgent : ${firefoxAgent}`))
  // console.log((`IExplorerAgent : ${IExplorerAgent}`))
  // console.log((`safariAgent : ${safariAgent}`))
  // console.log((`operaAgent : ${operaAgent}`))
  //
  // if (chromeAgent) {
  //     console.log("NavigatorUAData")
  //     // console.log(navigator.userAgentData.brands);
  //     // console.log(' ' + navigator.userAgentData.mobile);
  //     // console.log(' ' + navigator.userAgentData.platform);
  // }

  return userAgentString.indexOf('Chrome') > -1;
}
