var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      staticClass: "rounded",
      attrs: { persistent: "", width: "800", "retain-focus": "" },
      model: {
        value: _vm.select_application_open,
        callback: function($$v) {
          _vm.select_application_open = $$v
        },
        expression: "select_application_open"
      }
    },
    [
      _c(
        "v-card",
        {
          staticClass: "grey lighten-3",
          attrs: { dark: "", outlined: "", rounded: "lg" }
        },
        [
          _c(
            "v-card-title",
            { staticClass: "trade-blue-text text-h5 ml-2" },
            [
              _c(
                "v-flex",
                [
                  _c("span", [_vm._v(" Please select custom model to use: ")]),
                  _c("help-link", {
                    staticClass: "mr-2",
                    staticStyle: { float: "right" },
                    attrs: { help_id: "58" }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-radio-group",
                        {
                          staticClass: "mt-0",
                          attrs: { "hide-details": "" },
                          model: {
                            value: _vm.selected_application,
                            callback: function($$v) {
                              _vm.selected_application = $$v
                            },
                            expression: "selected_application"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "overflow-y-auto pa-2 rounded",
                              staticStyle: { "max-height": "400px" }
                            },
                            [
                              _vm._l(_vm.available_applications(), function(
                                subscription,
                                ix
                              ) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      key: ix,
                                      staticClass:
                                        "filterItem trade-blue-text outline"
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { "no-gutters": "" } },
                                        [
                                          _c(
                                            "v-col",
                                            { staticClass: "trade-blue-text" },
                                            [
                                              _c("v-radio", {
                                                key: ix,
                                                staticClass:
                                                  "caption trade-blue-text",
                                                attrs: { color: "#1f3c5f" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function() {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "trade-blue-text ",
                                                              class: {
                                                                "font-weight-bold":
                                                                  subscription.default
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  subscription[
                                                                    "name"
                                                                  ]
                                                                ) + " "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      },
                                                      proxy: true
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              })
                            ],
                            2
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "text-right mr-8 mt-1 mb-1" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "trade-blue mr-2 v-btn--outlined",
                  on: { click: _vm.set_selection }
                },
                [_vm._v("Apply ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "trade-blue mr-2 v-btn--outlined",
                  attrs: { disabled: !_vm.available_applications().length > 0 },
                  on: { click: _vm.set_default_value }
                },
                [_vm._v("Set as default ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }