import { render, staticRenderFns } from "./browser_compat_box.vue?vue&type=template&id=338b7af2&scoped=true&"
import script from "./browser_compat_box.vue?vue&type=script&lang=js&"
export * from "./browser_compat_box.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "338b7af2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/application/development/trade-dsm-frontend_2022/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('338b7af2')) {
      api.createRecord('338b7af2', component.options)
    } else {
      api.reload('338b7af2', component.options)
    }
    module.hot.accept("./browser_compat_box.vue?vue&type=template&id=338b7af2&scoped=true&", function () {
      api.rerender('338b7af2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/application-level/js_functions/browser_compat_box.vue"
export default component.exports