var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "outline rounded pa-2" }, [
      _c("p", [_vm._v("IMPORTANT NOTE!")]),
      _c("p", { staticClass: "text-h6" }, [
        _vm._v("Your current browser is not combatible with this application.")
      ]),
      _c("p", { staticClass: "caption" }, [
        _vm._v(" Please Install "),
        _c("a", { attrs: { href: "https://www.google.com/chrome" } }, [
          _vm._v("Chrome")
        ]),
        _vm._v(" or "),
        _c(
          "a",
          { attrs: { href: "https://www.microsoft.com/en-us/edge/download" } },
          [_vm._v("Edge")]
        ),
        _vm._v(" to log in. ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }